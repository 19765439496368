<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>退款</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-merchants-info">
            <span>退款</span>
            <div class="flex">
                <div class="sub-merchants-container">
                    <div class="input-item">
                        <div class="input-item-title">{{ $getTitleByType('会议名称', pid.cid) }}</div>
                        <el-select
                            ref="pName"
                            v-model="pName"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            filterable
                            @change="proChange">
                            <el-option
                                v-for="(item, index) in projectOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="index">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="input-item">
                        <div class="input-item-title">所属学会</div>
                        <el-select
                            v-model="society"
                            :placeholder="`请选择所属学会（请选择${$getTitleByType('会议名称',pid.cid)}）`"
                            @change="societyChange"
                            @visible-change="clickProject">
                            <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="input-item">
                        <div class="input-item-title">{{ $getTitleByType('会议日期', pid.cid) }}</div>
                        <el-input
                            v-model="pid.time"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            disabled
                        ></el-input>
                    </div>
                    <div class="input-item">
                        <div class="input-item-title">{{ $getTitleByType('大会主席', pid.cid) }}</div>
                        <el-input
                            v-model="pid.chairman"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            disabled
                        ></el-input>
                    </div>
                    <div class="input-item">
                        <div class="input-item-title">协议类型</div>
                        <el-radio-group v-model="tuikuan_type" @change="typeChange">
                            <el-radio :label="1">上游协议</el-radio>
                            <el-radio :label="2">下游协议</el-radio>
                        </el-radio-group>
                    </div>

                    <div v-if="tuikuan_type === 1">
                        <div class="input-item">
                            <div class="input-item-title">上游协议</div>
                            <el-select
                                v-model="xid"
                                placeholder="请选择所属上游协议"
                                @change="agreementChange"
                                @visible-change="clickProject">
                                <el-option
                                    v-for="item in agreementOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="input-item">
                            <div class="input-item-title">支持类别</div>
                            <el-radio-group v-model="payment_type" disabled>
                                <el-radio :label="1">赞助</el-radio>
                                <el-radio :label="2">捐赠</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="input-item">
                            <div class="input-item-title">企业名称(全称)</div>
                            <el-input
                                v-model="pay_item"
                                disabled
                                placeholder="请输入企业名称"></el-input>
                        </div>
                    </div>

                    <div v-else>
                        <div class="input-item">
                            <div class="input-item-title">下游协议</div>
                            <el-select
                                v-model="xid"
                                placeholder="请选择所属下游协议"
                                @change="htChange"
                                @visible-change="clickProject">
                                <el-option
                                    v-for="item in htOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="input-item">
                            <div class="input-item-title">退款类型</div>
                            <el-radio-group v-model="payment_type" disabled>
                                <el-radio :label="1">会议基金</el-radio>
                                <el-radio :label="2">专项基金</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="input-item">
                            <div class="input-item-title">委托项目</div>
                            <el-input
                                v-model="pay_item"
                                disabled
                                placeholder="请输入委托项目"></el-input>
                        </div>
                    </div>

                    <div class="input-item">
                        <div class="input-item-title">退款金额</div>
                        <el-input
                            v-model="pay_amount"
                            placeholder="请输入退款金额"
                            type="number"
                            @input="setAmount(0)"
                            @mousewheel.native.prevent></el-input>
                        <div class="money">万元</div>
                    </div>
                    <!--<div class="input-item">-->
                    <!--<div class="input-item-title">其中会议金额</div>-->
                    <!--<el-input-->
                    <!--v-model="meeting_decimal"-->
                    <!--type="number"-->
                    <!--placeholder="请输入其中会议金额"-->
                    <!--@input="setAmount(2)"-->
                    <!--&gt;</el-input>-->
                    <!--<div class="money">万元</div>-->
                    <!--</div>-->
                    <!--<div class="input-item">-->
                    <!--<div class="input-item-title">其中专项金额</div>-->
                    <!--<el-input-->
                    <!--v-model="self_amount"-->
                    <!--type="number"-->
                    <!--placeholder="请输入其中专项金额"-->
                    <!--@input="setAmount(1)"-->
                    <!--&gt;</el-input>-->
                    <!--<div class="money">万元</div>-->
                    <!--</div>-->
                    <div class="input-item">
                        <div class="input-item-title">收款单位</div>
                        <el-autocomplete
                            v-model="payee"
                            :fetch-suggestions="querySearch"
                            :trigger-on-focus="false"
                            class="inline-input"
                            placeholder="请输入收款单位"
                            value-key="name"
                            @select="payeeChange"></el-autocomplete>
                    </div>
                    <div class="input-item">
                        <div class="input-item-title">收款单位开户行</div>
                        <el-input
                            v-model="bank_name"
                            placeholder="请输入收款单位开户行"
                        ></el-input>
                    </div>
                    <div class="input-item">
                        <div class="input-item-title">收款单位账号</div>
                        <el-input
                            v-model="bank_code"
                            placeholder="请输入收款单位账号"
                        ></el-input>
                    </div>
                    <!--<div class="input-item">-->
                    <!--<div class="input-item-desc">费用明细单</div>-->
                    <!--<el-input  v-model="detail" placeholder="请输入费用明细单"></el-input>-->
                    <!--</div>-->
                    <!--<div class="input-item">-->
                    <!--<div class="input-item-title">付款交接文件</div>-->
                    <!--<el-checkbox-group v-model="fukuan_jiaojie">-->
                    <!--<el-checkbox label="1">支出凭单</el-checkbox>-->
                    <!--<el-checkbox label="2">下游专票</el-checkbox>-->
                    <!--<el-checkbox label="3">下游协议</el-checkbox>-->
                    <!--<el-checkbox label="4">项目明细</el-checkbox>-->
                    <!--</el-checkbox-group>-->
                    <!--</div>-->
                    <!--<div class="input-item">-->
                    <!--<div class="input-item-title">结算交接文件</div>-->
                    <!--<el-checkbox-group v-model="jiesuan_jiaojie">-->
                    <!--<el-checkbox label="1">会议结算单</el-checkbox>-->
                    <!--<el-checkbox label="2">电子结算单</el-checkbox>-->
                    <!--</el-checkbox-group>-->
                    <!--</div>-->
                    <div class="input-item">
                        <div class="input-item-desc">备注</div>
                        <el-input
                            v-model="remark"
                            :rows="5"
                            placeholder="请输入备注"
                            resize="none"
                            type="textarea"
                        ></el-input>
                    </div>
                    <approvalList
                        :approvalMembers="approvalMembers"
                        :copyMembers="copyMembers"
                        :isNoHave="!id"
                        :society="society"
                        :typeClassify="9"
                        @setApproval="setApproval"
                        @setMember="setMember"
                    ></approvalList>

                    <el-button :disabled="requestState" :loading="requestState" type="primary" @click="subApproval">提交
                    </el-button>
                </div>
                <div v-if="teshuApprovals.length > 0" class="info-list flex flex-direction padding-sm margin-top-sm margin-left flex-sub">
                    <span>特殊审批</span>
                    <div v-for="(item,index) in teshuApprovals" :key="index" class="info-item flex flex-direction">
                        <div class="name">{{ item.add_time }}</div>
                        <div class="remark margin-top-xs">{{ item.remark }}</div>
                        <div class="file margin-top-xs">
                            <a v-for="(file, index) in item.files" :key="index" :href="file.filePath | filePath"
                               target="_blank">{{ file.fileName }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import approvalList from "../../components/approvalList.vue";
import {webUrl} from "../../tools";

export default {
    components: {
        ElButton,
        ElInput,
        approvalList,
    },
    name: "refund",
    data() {
        return {
            id: "",
            sign_code: "",
            //  学会数据
            society: "",
            societyOptions: [],
            //  会议数据
            pid: "",
            pName: "",
            projectOptions: [],
            xid: "",
            tuikuan_type: 1,
            payment_type: "",
            agreementOptions: [],
            htOptions: [],
            pay_item: "",
            pay_amount: "",
            self_amount: "",
            meeting_decimal: "",
            payee: "",
            fukuan_jiaojie: [],
            jiesuan_jiaojie: [],
            detail: "",
            shiyou: "",
            bank_name: "",
            bank_code: "",
            remark: "",

            shenpi: "",
            copy: "",
            approvalMembers: [],
            copyMembers: [],
            teshuApprovals: [],

            restaurants: [],

            requestState: false
        };
    },
    created() {
        this.id = this.$route.query.id ? this.$route.query.id : '';
        this.$emit("setIndex", [4, 25]);
        this.init();
    },
    methods: {
        querySearch(queryString, cb) {
            const restaurants = this.restaurants;
            const results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.payee
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) !== -1
                );
            };
        },
        payeeChange(e) {
            this.payee = e.payee
            this.bank_name = e.bank_name
            this.bank_code = e.bank_no
        },
        setAmount(type) {
            if (type === 0) {
                this.self_amount = "0";
                this.meeting_decimal = "0";
                this.pay_amount = this.pay_amount.replace(/[^0-9 .]/g, "");
            } else if (type === 1) {
                if (this.pay_amount) {
                    if (this.self_amount) {
                        this.meeting_decimal = parseFloat((
                            Number(this.pay_amount).toFixed(6) - Number(this.self_amount).toFixed(6)).toFixed(6));
                    } else {
                        this.meeting_decimal = parseFloat(Number(this.pay_amount).toFixed(6));
                    }
                } else {
                    if (this.self_amount && this.meeting_decimal) {
                        this.pay_amount = parseFloat((
                            Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                    }
                }
            } else {
                if (this.pay_amount) {
                    if (this.meeting_decimal) {
                        this.self_amount = parseFloat((
                            Number(this.pay_amount).toFixed(6) - Number(this.meeting_decimal).toFixed(6)).toFixed(6));
                    } else {
                        this.self_amount = parseFloat(Number(this.pay_amount).toFixed(6));
                    }
                } else {
                    if (this.self_amount && this.meeting_decimal) {
                        this.pay_amount = parseFloat((
                            Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                    }
                }
            }
        },
        //  提交审核
        subApproval() {
            if (!this.pid) {
                this.$message.error(this.$refs.pName.placeholder);
                return;
            }
            if (!this.society) {
                this.$message.error("请选择所属学会");
                return;
            }
            if (!this.xid) {
                this.$message.error("请选择协议");
                return;
            }
            // if (this.teshuApprovals.length === 0) {
            //     this.$message.error("未发布特殊审批");
            //     return;
            // }
            if (!this.pay_amount) {
                this.$message.error("请选择退款金额");
                return;
            }
            if (this.meeting_decimal === "") {
                this.$message.error("请输入其中会议金额");
                return;
            }
            if (this.self_amount === "") {
                this.$message.error("请输入其中专项金额");
                return;
            }
            if (!this.payee) {
                this.$message.error("请选择收款单位");
                return;
            }
            if (!this.bank_name) {
                this.$message.error("请输入开户行");
                return;
            }
            if (!this.bank_code) {
                this.$message.error("请输入银行账户");
                return;
            }
            //                if (!this.detail) {
            //                    this.$message.error("请输入费用明细单")
            //                    return
            //                }

            //                if (this.jiesuan_jiaojie.length===0) {
            //                    this.$message.error("请选择结算交接文件")
            //                    return
            //                }
            //                if (!this.remark) {
            //                    this.$message.error("请输入备注")
            //                    return
            //                }
            if (this.shenpi.indexOf("-1") !== -1) {
                this.$message.error("请完善财务人员");
                return;
            }
            let arr = this.shenpi.split(",");
            if (arr[0] === arr[1]) {
                this.$message.error("审批人与财务人员不能相同");
                return;
            }
            this.requestState = true
            //添加
            this.$api.apiContent
                .addRefund({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sid: this.society,
                    pid: this.pid.id,
                    tuikuan_type: this.tuikuan_type,
                    xid: this.xid,
                    sign_code: this.sign_code,
                    pay_item: this.pay_item,
                    payment_type: this.payment_type,
                    pay_amount: this.pay_amount,
                    self_amount: this.self_amount,
                    meeting_decimal: this.meeting_decimal,
                    payee: this.payee,
                    bank_name: this.bank_name,
                    bank_no: this.bank_code,
                    detail: this.detail,
                    fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                    jiesuan_jiaojie: this.jiesuan_jiaojie.join(","),
                    remark: this.remark,
                    shenpi: this.shenpi,
                    copy: this.copy,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            sid: this.society,
                            pid: this.pid.id,
                            tuikuan_type: this.tuikuan_type,
                            xid: this.xid,
                            sign_code: this.sign_code,
                            pay_item: this.pay_item,
                            payment_type: this.payment_type,
                            pay_amount: this.pay_amount,
                            self_amount: this.self_amount,
                            meeting_decimal: this.meeting_decimal,
                            payee: this.payee,
                            bank_name: this.bank_name,
                            bank_no: this.bank_code,
                            detail: this.detail,
                            fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                            jiesuan_jiaojie: this.jiesuan_jiaojie.join(","),
                            remark: this.remark,
                            shenpi: this.shenpi,
                            copy: this.copy,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.requestState = false
                    this.$message.success(res.msg);
                    this.$router.push({
                        name: "refund",
                        query: {id: res.data.id, type: 1},
                    });
                    //                    this.backPage()
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },

        //  选择会议
        proChange(index) {
            let value = this.projectOptions[index];
            value.time =
                this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                " 至 " +
                this.$tools.ZHDate(new Date(value.end_time * 1000));
            this.pName = value.name;
            this.pid = value;
            this.society = "";
            this.xid = "";
            this.pay_item = "";
            this.payment_type = "";

            this.getSpecialQueryByName();
            this.getSociety();
        },
        societyChange() {
            this.xid = ""
            this.pay_item = "";
            this.payment_type = "";
        },
        typeChange() {
            this.xid = ""
            this.pay_item = "";
            this.payment_type = "";
            if (this.society) {
                if (this.tuikuan_type === 1) {
                    this.getAgreement()
                } else {
                    this.getContractList()
                }
            }

        },
        //  选择合同
        htChange(id) {
            let item = this.htOptions.filter((item) => {
                return item.id === id;
            });
            this.pay_item = item[0].payment;
            this.payment_type = item[0].payment_type;
            // this.getSpecialQueryByName(item[0].corporate_name)
        },
        //  选择合同
        agreementChange(id) {
            let item = this.agreementOptions.filter((item) => {
                return item.id === id;
            });
            this.pay_item = item[0].corporate_name;
            this.payment_type = item[0].categories;
            this.pay_amount =  Number(item[0].amount) * -1
            this.setAmount(0)
            // this.getSpecialQueryByName(this.pay_item)
        },
        //  选择所属学会点开后没选择
        clickProject(view) {
            if (!this.pid && view) {
                this.$message.error("请选择会议名称");
            }
        },
        //  获取协议
        getSpecialQueryByName() {
            this.$api.apiContent
                .getSpecialQueryByName({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    search_pid: this.pid.id,
                    // search_sid: this.society,
                    // search_enterprise_name: search_enterprise_name,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            search_pid: this.pid.id,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.map((item) => {
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000), 1);
                    });
                    this.teshuApprovals = res.data
                })
                .catch(() => {
                    this.teshuApprovals = [];
                });
        },
        //  获取协议
        getAgreement() {
            this.$api.apiContent
                .getZanzhuTuiKuan({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid.id,
                    sid: this.society,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid.id,
                            sid: this.society,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.map((item) => {
                        item.name =
                            (item.categories === 1 ? "赞助" : "捐赠") +
                            "/" + item.corporate_name + '/' + item.contacts + '/' + item.corporate_name_remarks
                    });
                    this.agreementOptions = res.data;
                    if (this.xid) {
                        let item = res.data.filter((item) => {
                            return item.id === this.xid;
                        });
                        this.xid = item.id;
                        this.pay_item = item.corporate_name;
                        this.payment_type = item.categories;
                        this.pay_amount =  Number(item.amount) * -1
                    } else {
                        this.xid = res.data[0].id;
                        this.pay_item = res.data[0].corporate_name;
                        this.payment_type = res.data[0].categories;
                        this.pay_amount = Number(res.data[0].amount) * -1
                    }
                    this.setAmount(0)
                    this.getSpecialQueryByName(this.pay_item)
                })
                .catch(() => {
                    this.agreementOptions = [];
                });
        },
        //  获取合同
        getContractList() {
            this.$api.apiContent
                .getContractList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: this.pid.id,
                    sid: this.society,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.pid.id,
                            sid: this.society,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.map((item) => {
                        item.name =
                            item.corporate_name +
                            "(" +
                            this.$tools.ZHDate(new Date(item.add_time * 1000), 6) +
                            ")";
                    });
                    this.htOptions = res.data;
                    if (res.data.length > 0) {
                        if (this.xid) {
                            let item = this.htOptions.filter((item) => {
                                return item.id === this.xid;
                            })[0];
                            this.xid = item.id;
                            this.pay_item = item.payment;
                            this.payment_type = item.payment_type;
                        } else {
                            this.xid = res.data[0].id;
                            this.pay_item = res.data[0].payment;
                            this.payment_type = res.data[0].payment_type;
                        }
                    } else {
                        this.xid = "";
                        this.pay_item = "";
                        this.payment_type = "";
                    }
                })
                .catch(() => {
                    this.htOptions = [];
                    this.xid = "";
                    this.pay_item = "";
                    this.payment_type = "";
                    this.surplus = "0";
                    this.meet = "0";
                    this.self = "0";
                });
        },
        //  获取学会
        getSociety() {
            this.$api.apiContent
                .getSocietyFromPro({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: this.pid.id,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.pid.id,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.societyOptions = res.data;
                    if (res.data.length === 1 && !this.society) this.society = res.data[0].id;
                })
                .catch(() => {
                    this.societyOptions = [];
                });
        },
        //  获取学会
        init() {
            this.$api.apiContent
                .getProject({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.map(item => {
                        item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                            + this.$tools.ZHDate(new Date(item.end_time * 1000)) + '/' + item.chairman + '/' + item.name
                    })
                    this.projectOptions = res.data;
                })
                .catch(() => {
                    this.projectOptions = [];
                });
            this.$api.apiContent
                .getPayCompanys({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.map(item => {
                        item.name = item.payee + "(" + item.bank_name + "/" + item.bank_no + ")"
                    })
                    this.restaurants = res.data;
                })
                .catch(() => {
                    this.restaurants = [];
                });
            if (this.id) {
                this.$api.apiContent
                    .getTuikuan({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
//            this.approvalMembers = res.data.shenpi;
//            this.shenpi = res.data.shenpi
//              .map((item) => {
//                return item.user_id;
//              })
//              .join(",");
                        this.copyMembers = res.data.copy;
                        this.copy = res.data.copy
                            .map((item) => {
                                return item.user_id;
                            })
                            .join(",");
                        this.tuikuan_type = res.data.tuikuan_type;
                        this.sign_code = res.data.sign_code;
                        this.society = res.data.sid;
                        this.xid = res.data.xid;
                        res.data.project.time =
                            this.$tools.ZHDate(
                                new Date(res.data.project.start_time * 1000)) +
                            " 至 " +
                            this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                        res.data.project.id = res.data.pid;
                        this.pid = res.data.project;
                        this.pName = this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) + " 至 "
                            + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000)) + '/' + res.data.project.chairman + '/' + res.data.project.name;
                        this.pay_item = res.data.pay_item;
                        this.pay_amount = parseFloat(Number(res.data.pay_amount).toFixed(6));
                        this.self_amount = res.data.self_amount;
                        this.meeting_decimal = res.data.meeting_decimal;
                        this.payee = res.data.payee;
                        //                        this.fukuan_jiaojie = res.data.fukuan_jiaojie
                        //                        this.jiesuan_jiaojie = res.data.jiesuan_jiaojie
                        this.shiyou = res.data.shiyou;
                        this.detail = res.data.detail;
                        this.bank_name = res.data.bank_name;
                        this.bank_code = res.data.bank_no;
                        this.remark = res.data.remark;
                        this.getSpecialQueryByName();
                        this.getSociety();
                    })
                    .catch(() => {
                    });
            }
        },
        //  返回
        backPage() {
            this.$tools.goBack();
        },
        //审批人
        setApproval(members) {
            this.shenpi = members;
        },
        //抄送人
        setMember(members) {
            this.copy = members;
        },
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
    },
    watch: {
        society() {
            if (this.tuikuan_type === 1) {
                this.getAgreement()
            } else {
                this.getContractList()
            }
        },

    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .info-list {
        border: 1px solid #e1e1e1;
        max-height: 800px;
        overflow-x: auto;

        .info-item {
            border: 1px solid #e1e1e1;
            margin-top: 20px;
            padding: 15px;

            .name {
                font-size: 18px;
                color: #333333;
            }

            .remark {
                font-size: 16px;
                color: #666666;
            }

            .file {
                display: flex;
                flex-direction: column;

                a {
                    font-size: 18px;
                    cursor: pointer;
                    margin-bottom: 8px;
                    display: block;
                    color: #3b77e7;

                    &:hover {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .sub-merchants-info {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        padding: 40px 25px;
        min-height: 800px;

        span {
            color: #000000;
            font-size: 24px;
        }

        .sub-merchants-container {
            width: 60%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .tip-red {
                color: red;
                font-weight: bold;
                margin-left: 23%;
                margin-bottom: 20px;
            }

            .input-item {
                display: flex;
                margin-bottom: 20px;
                flex-direction: row;
                align-items: center;
                overflow: hidden;

                .money {
                    flex-shrink: 0;
                    margin-left: 10px;

                }

                .red {
                    color: red;
                    font-weight: bold;
                }

                .money-count {
                    flex-shrink: 0;
                    margin-left: 10px;
                    font-size: 16px;
                }

                .input-item-title {
                    width: 20%;
                    text-align: center;
                    font-size: 16px;
                    margin-right: 20px;
                    color: #000000;
                    flex-shrink: 0;
                }

                .input-item-desc {
                    width: 20%;
                    align-self: flex-start;
                    margin-top: 10px;
                    margin-right: 20px;
                    text-align: center;
                    font-size: 16px;
                    color: #000000;
                    flex-shrink: 0;
                }

                .el-autocomplete,
                .el-input,
                .el-select,
                .el-textarea,
                .el-radio-group,
                .el-date-editor,
                .el-upload {
                    flex-grow: 1;
                    resize: none;
                }
            }

            > .el-button {
                margin-top: 30px;
                align-self: center;
                width: 40%;
            }
        }
    }
}
</style>
